<template>
  <div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">

    <div v-if="SelectedExam">
      <DeleteExamModal :SelectedExamData="SelectedExamData" :closeDeleteExam="closeDeleteExam" :closeDeleteExamWithDelete="closeDeleteExamWithDelete" />
    </div>
   
    <div class="flex h-full w-full" :class="isLoading ? 'hidden' : 'block'">
        <div v-if="modules.length == 0" class="border rounded-lg w-full text-center flex justify-center items-center">
          <el-result
            icon="info"
            title="Aucun module"
            subTitle="Il y a aucun module pour cette année "
          >
          </el-result>
        </div>

        <div v-else class="flex sm:flex-row w-full flex-col h-full">

            <div class="sm:w-64">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
                </div>
        
                <div class="rounded-lg">
                  <!-- <el-scrollbar height="413px" class="w-full border rounded-md bg-white"> -->
                    <el-menu
                        class="el-menu-vertical-demo w-full border rounded-lg text-center bg-white"
                        @select="handleSelect"
                        @open="handleOpen"
                        @close="handleClose"
                        :collapse="isCollapse"
                    >
                      <el-scrollbar height="413px" class="w-full rounded-lg bg-white">
                        <el-menu-item v-for="module in modules" :key="module.id" v-bind:index="module.id">
                          <template #title>{{ module.title  }} </template>
                        </el-menu-item>
                      </el-scrollbar>
                    </el-menu>
                  <!-- </el-scrollbar> -->
                </div>

            </div>


            <div class="flex flex-col w-full sm:ml-6 sm:mt-0  ">
              <div class="inline-flex justify-between items-center">
                <div class="mb-2">
                  <p v-if="!addExam" class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des examens</p>
                  <p v-else class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Ajouter un examen</p>
                </div>
                <div></div>
              </div>


              <div class="border rounded-lg py-3 h-full">
                 
                <div v-show="!addExam && selectedModuleId && !updateExam" class="inline-flex w-full px-3 justify-between items-center mb-5">
                  <div class="relative">
                    <el-input v-model="searchExam" placeholder="Recherche par titre" />
                    <button  class="absolute right-0 top-0 my-3 mr-3">
                      <svg class="h-4 w-4 fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
                      </svg>
                    </button>
                  </div>
                  <button @click="addExam=true" class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                     Ajouter un examen
                  </button>
                </div>

                <div v-show="(addExam || updateExam) && selectedModuleId " class="inline-flex w-full px-3 justify-between items-center mb-5">
                  <button @click="addExam=false ,updateExam=false " class="inline-flex items-center py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                     <el-icon color="#fff" :size="20">
                        <back />
                      </el-icon>
                  </button>
                  <div></div>
                </div>
                  

                <div :class="isLoadingExams ? 'block' : 'hidden'" class="flex items-center justify-center sm:mt-20 w-full">
                  <!--<div class="fulfilling-square-spinner sm:mt-2">
                    <div class="spinner-inner"></div>
                  </div>-->
                  <div class="loading">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div>

                <div :class="isLoadingExams ? 'hidden' : 'block'">
                  <div v-if="exams.length == 0 && !addExam">
                    <div class="flex justify-center items-center h-full p-5 " :class="selectedModuleId ? 'sm:mt-10' : 'sm:mt-16'">
                      <el-result
                        icon="info"
                        :title="selectedModuleId ? 'Aucun examen' : 'Aucun module sélectionné'"
                        :subTitle="selectedModuleId ? 'Il y a aucun examen pour ce module' : 'Veuillez sélectionner un module'"
                      >
                      </el-result>
                    </div>
                  </div>

                  <div v-else>
                    <div class="mx-4">
                        <div v-if="!updateExam">
                            <div v-if="!addExam">
                              <ExamsList :exams="exams" @selected-exam="openDeleteExam" @update-exam="openUpdateExam" :nextPage="nextPage" :prevPage="prevPage" :refine="refine" :lastp="lastp" :currentp="currentp" />
                            </div>

                            <div v-else>
                              <AddExamQuestions :selectedModuleId="selectedModuleId" :resetAddExam="resetAddExam" @add-succes="succesAdd" />
                            </div>
                        </div>
                      
                        <div v-else>
                              <UpdateExamQuestions :selectedModuleId="selectedModuleId" :SelectedExamData="SelectedExamData" :resetupdateExam="resetupdateExam" @update-succes="succesUpdate" />
                        </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
    </div>


    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

  </div>
</template>

<script>
import {Back} from '@element-plus/icons';
import DeleteExamModal from '../dashboard/DeleteExamModal.vue'
import axios from 'axios';
import { ElMessage } from 'element-plus'
import ExamsList from '../dashboard/ExamsList.vue'
import AddExamQuestions from '../dashboard/AddExamQuestions.vue'
import UpdateExamQuestions from '../dashboard/UpdateExamQuestions.vue'


export default {
 components: {
    Back,
    ElMessage,
    DeleteExamModal,
    ExamsList,
    AddExamQuestions,
    UpdateExamQuestions
  },

  
  data (){ 
    return{
      isLoading : false,
      isLoadingExams : false,

      selectedModuleId: null,
      modules:[],
      addExam: false,
      updateExam:false,
      exams: [],


      SelectedExam: false , 
      SelectedExamData : {
        id: null,
        name: null ,
      },

      // pagination
      nextp : null,
      prevp : null,
      lastp : null,
      currentp : null,

      // recherche
      searchExam : null,

      

    }
  },

  mounted () {
    this.isLoading = true;
    axios
        .get(process.env.VUE_APP_API_BASE_URL+"/moduleofyear/"+this.$route.query.year, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.modules = data.modules;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },
  methods:{
    openDeleteExam(exam){
      this.SelectedExam = true;
      this.SelectedExamData.id = exam.id;
      this.SelectedExamData.name = exam.name;
    },
    closeDeleteExamWithDelete(){
      this.exams = this.exams.filter((tab) => tab.id !== this.SelectedExamData.id)
      this.SelectedExam = false;
      this.SelectedExamData.id = null;
      this.SelectedExamData.name = null;
      this.succesDelete();
    },
    closeDeleteExam(){
      this.SelectedExam = false;
      this.SelectedExamData.id = null;
      this.SelectedExamData.name = null;
    },


    openUpdateExam(exam){
      this.updateExam = true;
      this.SelectedExamData.id = exam.id;
      this.SelectedExamData.name = exam.name;
    },
    
   
  

    handleSelect(key) {
        this.addExam = false
        this.isLoadingExams = true;
        this.selectedModuleId = key;
        this.prevp = null;
        this.nextp = null;
        this.currentp = null;
        this.lastp = null;
         axios
        .get(process.env.VUE_APP_API_BASE_URL+"/examensofmodule/"+key, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.exams = data.examens.data;
          this.lastp = data.examens.last_page;
          this.nextp = data.examens.next_page_url;
          this.currentp = data.examens.current_page;
          this.isLoadingExams = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoadingExams = true;
        });
      },

    nextPage(){
        this.isLoadingExams = true;
        axios.get(this.nextp, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.exams = data.examens.data;
          this.lastp = data.examens.last_page;
          this.nextp = data.examens.next_page_url;
          this.prevp = data.examens.prev_page_url;
          this.currentp = data.examens.current_page;
          this.isLoadingExams = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoadingExams = false;
        });
    },

    prevPage(){
        this.isLoadingExams = true;
        axios.get(this.prevp, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.exams = data.examens.data;
          this.lastp = data.examens.last_page;
          this.nextp = data.examens.next_page_url;
          this.prevp = data.examens.prev_page_url;
          this.currentp = data.examens.current_page;
          this.isLoadingExams = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoadingExams = false;
        });
    },

    refine(page){
        this.isLoadingExams = true;
        axios.get(process.env.VUE_APP_API_BASE_URL+"/examensofmodule/"+this.selectedModuleId+"/?page="+page, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.exams = data.examens.data;
          this.lastp = data.examens.last_page;
          this.nextp = data.examens.next_page_url;
          this.prevp = data.examens.prev_page_url;
          this.currentp = data.examens.current_page;
          this.isLoadingExams = false;
        })
        .catch(() => {
          this.errorm();
          this.isLoadingExams = false;
        });
      },


    resetAddExam(){
      this.addExam = false;
    },
    resetupdateExam(){
      this.updateExam = false;
    }

    
  },

  setup() {
    const succesAdd = () => {
      ElMessage({
        message: "L'examen a été ajouté avec succès.",
        type: 'success',
      })
    }
    const succesUpdate = () => {
      ElMessage({
        message: "L'examen a été modifié avec succès.",
        type: 'success',
      })
    }
    const succesDelete = () => {
      ElMessage({
        message: "L'examen a été supprimé avec succès.",
      })
    }
     const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return {
      succesAdd,
      succesUpdate,
      succesDelete,
      errorm
    }
  },
}
</script>


<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}


</style>

