<template>
    <div>
        <div v-show="AddQuestionForClinicalCaseModal">
          <AddQuestionForClinicalCaseExamModal @add-question-clinicalcas="addQuestionOnClinicalCase" :closeAddQuestionForClinicalCaseModal="closeAddQuestionForClinicalCaseModal" />
        </div>

        <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
            <!--   add modal   -->
        <div :class="AddQuestionForClinicalCaseModal ? 'hidden' : 'block'" class="z-40 inset-0 fixed flex justify-center items-center">
          <div class=" flex flex-col w-2/3 sm:w-5/6 lg:w-1/2 max-w-lg mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Ajouter une question</p>
              <svg @click="closeAddQuestionModal" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">
              <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3">   
                <div v-show="((!existingQuestion) && (!newQuestion))" class="w-full">
                  <button @click="existingQuestion=true"  class="py-2 px-4 mx-2 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                    Question existante
                  </button>
                  <button @click="loadCourses" class="py-2 px-4 mx-2 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                    Nouvelle question
                  </button>
                </div>

                <!-- une nouvelle question -->
                <div class="flex flex-col justify-center items-center w-full" v-show="newQuestion">
                  <div class="flex sm:flex-row flex-col justify-between w-full">
                    <select v-model="questionType" name="" id="" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <option value="" disabled selected>
                        Type de question
                      </option>
                      <option value="qcs/qcm">Question à choix simple/multiple</option>
                      <option value="qroc">Question à réponse ouverte courte</option>
                      <option value="clinicalcas">Cas clinique</option>
                    </select>
                    <select @change="getIdCourse($event)" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <option value="" selected>
                        Aucun cours
                      </option>
                      <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.title }}</option>
                    </select>
                  </div>
                  <div class="sm:flex w-full">
                    <textarea v-model="field" placeholder="Veuillez introduire votre question ..." name="" id="" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                  </div>
                  <div v-show="questionType=='qroc'" class="sm:flex w-full">
                    <textarea v-model="responsetype" placeholder="Veuillez introduire la réponse type" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                  </div>
                  <div v-show="questionType=='qcs/qcm'" class="sm:flex sm:flex-col w-full">

                    <button @click="addSupposition=true" class="py-2 px-4 m-1 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                      Ajouter une supposition
                    </button>
                    <div v-show="addSupposition" class="flex flex-col m-1 border rounded-lg p-3">
                      <div class="flex flex-row justify-center items-center">
                        <input v-model="supposition" type="text" placeholder="Veuillez introduire une supposition" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                        <el-switch v-model="state" class="mx-2"> </el-switch>
                      </div>
                      <button @click="confirmSupposition" class="py-2 px-4 mt-2 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                        Confirmer l'ajout
                      </button>
                    </div>


                    <el-scrollbar max-height="128px" class="my-1">
                      <div
                        class="bg-gray-200 my-1 mx-3 p-1 rounded-lg flex flex-row justify-between items-center" v-for="supposition in suppositions" :key="supposition.id">
                        <div class="flex flex-row items-center">
                          <div
                            :class="
                              supposition.state == '1' ? 'bg-green-400' : 'bg-red-400'
                            "
                            class="h-2 w-2 m-2 rounded"
                          ></div>
                          {{ supposition.proposal }}
                        </div>
                        <svg
                          @click="onDeleteSupp(supposition.id)"
                          class="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </div>
                    </el-scrollbar>
                  </div>
                  
                  <div v-show="questionType=='clinicalcas'" class="sm:flex sm:flex-col w-full">
                    <button @click="AddQuestionForClinicalCaseModal=true" class="py-2 px-4 mt-1 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                      Ajouter une question
                    </button>
                  </div>

                </div>

                <!-- une question existante -->
                <div class="flex flex-col justify-center w-full" v-show="existingQuestion">
                  <div>
                    <form class="relative">
                      <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                      </svg>
                      <select @change="loadExistingQuestions($event)" v-model="searchExistingQuestion" class="focus:border-light-blue-500 focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10" type="text" aria-label="Filter projects">
                        <option value="" selected disabled>Type de question</option>
                        <option value="qcm">Question à choix multiple</option>
                        <option value="qcs">Question à choix unique</option>
                        <option value="qroc">Question à réponse ouverte courte</option>
                        <option value="clinicalcas">Cas clinique</option>
                      </select>
                    </form>
                  </div>
                  <div class="mt-2">
   

                    <el-table
                      ref="multipleTable"
                      :data="existingQuestionData"
                      style="width: 100%"
                      height="200"
                      @selection-change="selectedQuestionFunction"
                    >
                      <el-table-column type="selection" > </el-table-column>
                      
                      <el-table-column label="Question">
                        <template #default="scope">
                          <el-popover effect="light" trigger="hover" placement="top" :width="400">
                            <template #default>
                              <p>{{ scope.row.question }}</p>
                            </template>
                            <template #reference>
                              <div class="name-wrapper">
                                <el-tag size="medium">{{ scope.row.question }}</el-tag>
                              </div>
                            </template>
                          </el-popover>
                        </template>
                      </el-table-column>
          
                      
                    </el-table>


                    <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center mt-4">
                      <li>
                        <button :disabled="prevp === null" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                          @click.prevent="prevPage"
                        >
                          <span class="sr-only">Previous</span>
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>
                      <li v-for="page in lastp" :key="page">
                        <button class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                          :class=" page === currentp ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                          
                          @click.prevent="refine(page)"
                        >
                          {{ page }}
                        </button>
                      </li>
                      
                      <li>
                        <button class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                          @click.prevent="nextPage"
                        >
                          <span class="sr-only">Next</span>
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      </li>
                    </ul>

                  </div>



                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeAddQuestionModal"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="confirmeQuestion" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AddQuestionForClinicalCaseExamModal from '../dashboard/AddQuestionForClinicalCaseExamModal.vue'
import { ElMessage } from 'element-plus';

export default {
  components: {
    AddQuestionForClinicalCaseExamModal
  },
  data () {
    return {
      newQuestion: false,
      existingQuestion: false,
      questionType: '',
      
      courses: [],
      courseId: null,

      field: '',
      //suppositions
      addSupposition: false,
      supposition: null,
      state: true,
      suppositions: [],
      CountState: 0 ,
      responsetype: null,
      position : 1,


      AddQuestionForClinicalCaseModal : false,
      clinicalcase: [],

      searchExistingQuestion: '',
      existingQuestionData: [],

      LoadedselectecExistingQuestions:[],
      selectecExistingQuestions: [],
      nextp : null,
      prevp : null,
      lastp : null,
      currentp : null,

      typeQ : null,

      tags : []
    }
  },
  props:{
    closeAddQuestionModal:Function,
    addTab:Function,
    selectedModuleId: Number,
    tabIndex:Number,
  },

  mounted () {
    
  },

  methods: {
    loadCourses(){
      this.newQuestion=true;
      axios.get(process.env.VUE_APP_API_BASE_URL+"/courseofmodule/" + this.selectedModuleId, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        // this.isLoading = false;
        this.courses = data.courses;
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });
    },


    getIdCourse(event) {
      this.courseId = event.target.value;
    },

    loadExistingQuestions(event){
      this.existingQuestion=true;
      this.prevp = null;
      this.nextp = null;
      this.currentp = null;
      this.lastp = null;
      this.typeQ = event.target.value;
      axios.get(process.env.VUE_APP_API_BASE_URL+"/questionsofmoduletype/"+this.selectedModuleId+"/"+event.target.value, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        // this.isLoading = false;
        this.existingQuestionData = data.result.data;
        this.lastp = data.result.last_page;
        this.nextp = data.result.next_page_url;
        this.currentp = data.result.current_page;
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });
    },


    nextPage(){
      axios.get(this.nextp, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.existingQuestionData = data.result.data;
        this.lastp = data.result.last_page;
        this.nextp = data.result.next_page_url;
        this.prevp = data.result.prev_page_url;
        this.currentp = data.result.current_page;
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });
    },

    prevPage(){
      axios.get(this.prevp, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.existingQuestionData = data.result.data;
        this.lastp = data.result.last_page;
        this.nextp = data.result.next_page_url;
        this.prevp = data.result.prev_page_url;
        this.currentp = data.result.current_page;
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });
    },

    refine(page){
      axios.get(process.env.VUE_APP_API_BASE_URL+"/questionsofmoduletype/"+this.selectedModuleId+"/"+this.typeQ+"?page="+page, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        this.existingQuestionData = data.result.data;
        this.lastp = data.result.last_page;
        this.nextp = data.result.next_page_url;
        this.prevp = data.result.prev_page_url;
        this.currentp = data.result.current_page;
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });
    },
  

    confirmSupposition() {
      if (!this.supposition) {
        alert("veuillez entrer une supposition");
        return;
      }
      let nstate = null;
      if (this.state === true) { nstate ="1";} else {nstate = "0";}
      if (this.state === true) {this.CountState = this.CountState + 1;}

      this.suppositions = [...this.suppositions, {
        id : Math.floor(Math.random() * 1000),
        proposal: this.supposition,
        state: nstate,
      }];

      this.supposition = "";
      this.state = false;
    },

    onDeleteSupp(id) {
      this.suppositions = this.suppositions.filter(
        (supposition) => supposition.id !== id
      );
      this.CountState = 0;
      this.suppositions.forEach((supposition) => {
        if (supposition.state === "1") {
          this.CountState = this.CountState + 1;
        }
      });
    },

    AddExist(array){
      let pos = this.tabIndex + 1;
      array.forEach(item => {
          if(item.type === "qcm" || item.type === "qcs" ){
            const newQuestion = {
            id: item.id, 
            course_id: item.course_id,
            question: item.question,
            type: item.type,
            proposals: item.propositions,
            position : pos,
            tags : item.tags,
          };
          this.$emit("add-existing-question", newQuestion);
          }
          if(item.type == 'qroc' ){
            const newQuestion = {
              id: item.id, 
              course_id: item.course_id,
              question: item.question,
              type: item.type,
              reponsetype: item.response,
              position : pos,
              tags : item.tags,
            };  
          this.$emit("add-existing-question", newQuestion);
          }

        if(item.type !== 'qcm' && item.type !== 'qcs' && item.type !== 'qroc'){
            const newQuestion = {
              id: item.id,
              course_id: item.course_id,
              question: item.question,
              clinicalCase: item.questions,
              type: "clinicalcas",
              position : pos,
              tags : item.tags,
            };
        this.$emit("add-existing-question", newQuestion);
      }
      pos = pos+1;
      });
      
    },

    confirmeQuestion(){
      if(this.existingQuestion){
        axios.post(process.env.VUE_APP_API_BASE_URL+"/selectedquestions",{
          ids : this.selectecExistingQuestions,
          type : this.searchExistingQuestion
        },{
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({ data }) => {
        // this.isLoading = false;

        this.LoadedselectecExistingQuestions = data.result;
        this.AddExist(data.result);
      })
      .catch(() => {
        this.errorm();
        // this.isLoading = false;
      });

      }else{
      if (this.questionTypeM !== null) {
       if(this.questionType == 'qcs/qcm'){
         if (this.CountState !== 0) {
            let typeM = "";
            if (this.CountState === 1) {
              typeM = "qcs";
            } else {
              typeM = "qcm";
            }
            const newQuestion = {
            course_id: this.courseId,
            question: this.field,
            type: typeM,
            proposals: this.suppositions,
            position : this.tabIndex + 1,
            };
        this.$emit("add-question", newQuestion);
        this.CountState = 0;
      }
      }
      if(this.questionType == 'qroc'){
        const newQuestion = {
          course_id: this.courseId,
          question: this.field,
          type: this.questionType,
          reponsetype: this.responsetype,
          position : this.tabIndex + 1,
        };  
        this.$emit("add-question", newQuestion);
      }

      if(this.questionType == 'clinicalcas'){
        const newQuestion = {
          course_id: this.courseId,
          question: this.field,
          clinicalCase: this.clinicalcase,
          type: 'clinicalcas',
          position : this.tabIndex + 1,
        };
        this.$emit("add-question", newQuestion);
      }
          
    }
    }
        this.closeAddQuestionModal();
    
    },

    addQuestionOnClinicalCase(question){
      this.clinicalcase = [...this.clinicalcase, question];
    },

    closeAddQuestionForClinicalCaseModal(){
      this.AddQuestionForClinicalCaseModal = false
    },


    selectedQuestionFunction(val) {
      const result = [];
       val.forEach(e => {
          result.push(e['id']);
        });
      this.selectecExistingQuestions = result;
    },
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },

  setup(){
     const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }
    return{errorm}
  }

};
</script>
<style>

</style>
